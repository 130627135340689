import React from 'react'
import BlogListItem from './list-item'
const { sampleSize } = require('lodash')

const BlogRelatedPostsSidebar = ({ heading = "Related insights", posts }) => {
    if (posts.length) {
        return (
            <div className="c-article__related">
                <div className="c-article__related-title">{heading}</div>
                <div className="c-blog-listing c-blog-listing--article">
                    <div className="row">
                        {sampleSize(posts, 3).map((post, i) => {
                            return (
                                <BlogListItem key={i} post={post} hideSummary={true} />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default BlogRelatedPostsSidebar